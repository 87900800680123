import { h } from "preact";
import style from "./style.scss";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import SectionHeading from "../../../components/SectionHeading";
import BenefitItem from "../../../components/BenefitItemNSKOmakase";

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="/zennichi/naiso-kouji-omakase/flow/"
    >
      <p className={style.InquiryButtonText}>
        管理会社様ご利用登録
        <span className={style.InquiryButtonTextSmall}>（無料）</span>
      </p>
    </a>
  );
}

const faqList = [
  {
    questionText: "対応エリアはどこですか？",
    answerTex: `現在の対応エリアは一都三県になりますが、順次拡大予定です。\n詳しくはイタンジの担当者までお問い合わせください。`,
  },
  {
    questionText: "どのような施工会社様が施工しますか？",
    answerTex: (
      <>
        当社とパートナー契約を締結をしている認定施工会社様が立会・施工を行います。
        <br />
        また、内装工事くんは施工会社様のアウトソーシングに留まらず、イタンジの担当者が管理会社様の工事の要望をヒアリングし、責任を持って発注内容に適した施工会社様の選定、工事の品質管理をいたします。
      </>
    ),
  },
  {
    questionText: "工事単価を知りたいです。",
    answerTex: (
      <>
        主な工事単価は
        <a
          native
          className={style.FaqLink}
          href="#price"
          rel="noopener noreferrer"
        >
          料金費
        </a>
        をご参照ください。その他の工事単価につきましてはお問い合わせください。
      </>
    ),
  },
  {
    questionText: "入居中の修繕は依頼できますか？",
    answerTex: `可能です。案件の備考欄に入居中の物件である旨ご記載ください。\n現地調査の結果、工事が不要だった場合には、調査費用として6,000円がかかります。見積提出、工事発注の流れは原状回復工事と同様です。`,
  },
  {
    questionText: "工期はどれぐらいですか？",
    answerTex: `施工内容により変動しますが、クリーニングや壁紙張り替えなどの簡易的な工事であれば、工事発注（見積承認後）から10日以内での完了を目安としています。`,
  },
  {
    questionText: "システム利用料は発生しますか？",
    answerTex: `システムに関する導入初期費用、月額費用は無料です。お支払いいただく料金は、工事代金のみとなります。`,
  },
];

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function SecondaryButton(props) {
  return (
    <a native className={style.SecondaryButtonContainer} href={props.href}>
      <p className={style.SecondaryButtonText}>{props.text}</p>
    </a>
  );
}

function FAQCard(props) {
  return (
    <div className={style.FAQCardContainer} key={props.key}>
      <h3 className={style.FAQCardQuestionContainer}>
        <span className={style.FAQCardQuestionPrefix}>Q</span>
        <span className={style.FAQCardQuestionText}>{props.questionText}</span>
      </h3>
      <p className={style.FAQCardAnswerContainer}>
        <span className={style.FAQCardAnswerPrefix}>A</span>
        <span className={style.FAQCardAnswerText}>{props.answerText}</span>
      </p>
    </div>
  );
}

const ZennichiNaisoKoujiOmakase = () => (
  <>
    <main>
      <section className={style.FirstViewSection}>
        <ol className={style.BreadcrumbsArea}>
          <li className={style.Breadcrumbs}>
            <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
              TOP
            </a>
          </li>
          <li className={style.Breadcrumbs}>
            <a className={style.BreadcrumbsItem} href="">
              内装工事くんおまかせプラン
            </a>
          </li>
        </ol>
        <div className={style.FirstViewContainer}>
          <div className={style.FirstViewInnerWrapper}>
            <div className={style.FirstViewLogoArea}>
              <div className={style.FirstViewLogoAreaBg01}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={style.FirstViewLogoAreaBg02}>
                <img
                  className={style.FirstViewLogoImage}
                  src="/assets/service_logo/itandibbplus_NSK_omakase.svg"
                  alt="内装工事くん"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="167"
                  height="45"
                  viewBox="0 0 167 45"
                  fill="none"
                >
                  <path
                    d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={style.FirstViewLogoAreaBg03}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className={style.FirstViewTextWrapper}>
              <h1 className={style.FirstViewCatchCopy}>
                <span className={style.FirstViewCatchCopyText}>
                  原状回復・修繕工事を
                </span>
                <br />
                <span className={style.FirstViewCatchCopyText}>
                  まるっとおまかせ
                </span>
              </h1>
              <div className={style.FirstViewCatchCopySub}>
                <strong className={style.FirstViewCatchCopySubBr}>
                  施工会社様を
                </strong>
                <strong className={style.FirstViewCatchCopySubBr}>
                  確保したい方向け
                </strong>
              </div>
              <InquiryButton />
            </div>
            <div className={style.FirstViewVisualContainer}>
              <img
                className={style.FirstViewVisualImage}
                src="/assets/naiso_kouji_omakase/NSKVisualImage.svg"
                alt="内装工事くんおまかせプランの概念"
              />
            </div>
          </div>
          <div className={style.BgCircle} />
        </div>
      </section>

      <section className={style.AboutSection}>
        <div className={style.AboutWrapper}>
          <SectionHeading
            title={`内装工事くんおまかせプランとは`}
            subTitle="ABOUT"
          />
          <p className={style.AboutDescription}>
            原状回復工事における退去立会・修繕工事の現地調査から工事発注、工事完了確認までの面倒な業務をイタンジが代わりに実施します。
            <br />
            イタンジの担当者が管理会社様の要望をヒアリングし、パートナー施工会社様※の特徴や過去の工事の実績と照らし合わせ、最適な工事発注を実現します。
            <br />
            Web上でやりとりができるため、案件登録 / 見積承認 / 完了時確認
            の３ステップの承認作業だけで完結します。
          </p>
          <div className={style.AboutCaption}>
            ※パートナー施工会社様とは当社とパートナー契約を締結した施工会社様になります。イタンジが事前に面談を行い、反社チェックと定期的な工事の品質確認を実施しております。
          </div>
          <div className={style.AboutImageWrapper}>
            <picture className={style.AboutImageWrapper}>
              <source
                srcset="/assets/naiso_kouji_omakase/about_summary_sp_202311.png"
                media="(max-width: 500px)"
              />
              <img
                src="/assets/naiso_kouji_omakase/about_summary_laptop_202311.png"
                alt="工事発注の流れ"
                className={style.AboutImageContainer}
              />
            </picture>
          </div>
        </div>
      </section>
      <section className={style.ValueSection}>
        <div className={style.ValueWrapper}>
          <SectionHeading title={`内装工事くんでできること`} subTitle="VALUE" />
          <div className={style.ValueIssueWrapper}>
            <picture className={style.AboutImageWrapper}>
              <source
                srcset="/assets/naiso_kouji_omakase/value_issue_sp_202311.png"
                media="(max-width: 500px)"
              />
              <source
                srcset="/assets/naiso_kouji_omakase/value_issue_tablet_202311.png"
                media="(max-width: 1000px)"
              />
              <img
                src="/assets/naiso_kouji_omakase/value_issue_laptop_202311.png"
                alt="施工会社様が見つからず、お困りではありませんか？"
                className={style.ValueIssueContainer}
              />
            </picture>
          </div>
          <h3 className={style.ValueTitle}>内装工事くんのメリット</h3>
          <div className={style.ValueBenefitWrapper}>
            <BenefitItem
              serviceName="NSKOmakase"
              imgSrc="/assets/naiso_kouji_omakase/naiso_kouji_omakase_point01.png"
              imgAlt="工事会社様のネットワーク"
              title="ポイント 1"
              copy={`施工会社様の確保がスムーズ`}
              description={`イタンジの施工会社様ネットワークを駆使して、すぐに施工会社様を確保することができます。待ち時間なく工事を進めることができるので、空室期間の削減につながります。`}
            />
            <BenefitItem
              serviceName="NSKOmakase"
              imgSrc="/assets/naiso_kouji_omakase/naiso_kouji_omakase_point02.png"
              imgAlt="やりとりは4分の1に削減"
              title="ポイント ２"
              copy={`施工会社様との煩雑なやりとりをおまかせ`}
              description={`工事に関するやりとりはイタンジの担当者が間に入って調整します。退去立会や現地調査を依頼したあとは、報告があがるのを待つだけです。`}
            />
            <BenefitItem
              serviceName="NSKOmakase"
              imgSrc="/assets/naiso_kouji_omakase/naiso_kouji_omakase_point03.png"
              imgAlt="Web上に自動保存"
              title="ポイント ３"
              copy={`報告書などはWeb上に自動保存`}
              description={`やりとりした書類や写真はシステム上に自動保存されるので、保存のし直しや情報管理の手間がなくなります。`}
            />
          </div>
        </div>
      </section>
      <section className={style.InterviewSection}>
        <div className={style.InterviewWrapper}>
          <SectionHeading
            subTitle="USER'S VOICE"
            title="導入企業様"
            color="light"
          />
          <div className={style.InterviewVoiceWrapper}>
            <div className={style.InterviewVoiceCardWrapper}>
              <div className={style.InterviewVoiceCard}>
                <div className={style.InterviewVoiceImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/interview_voice_01.png"
                    alt=""
                    className={style.InterviewVoiceImage}
                  />
                </div>
                <div className={style.InterviewVoiceTextContainer}>
                  <div className={style.InterviewVoiceTextGroup}>
                    <p className={style.InterviewVoiceTextPosition}>
                      株式会社アイ・コーポレーション様
                    </p>
                    <p className={style.InterviewVoiceText}>
                      事務所での
                      <span className={style.InterviewVoiceStrong}>
                        業務時間を大幅に創出
                      </span>
                      でき、今まで
                      <span className={style.InterviewVoiceStrong}>
                        手が回らなかった業務にも時間が割けるように
                      </span>
                      なりました。おかげで働き方の改善にも繋がっています。
                    </p>
                  </div>

                  <div className={style.InterviewLinkContainer}>
                    <a
                      native
                      href="https://magazine.itandi.co.jp/casestudy/2130/"
                      className={style.InterviewLink}
                    >
                      インタビュー記事へ
                      <img
                        className={style.InterviewArrowIcon}
                        src="/assets/naiso_kouji_omakase/chevron-right.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className={style.InterviewVoiceCard}>
                <div className={style.InterviewVoiceImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/interview_voice_02.png"
                    alt=""
                    className={style.InterviewVoiceImage}
                  />
                </div>
                <div className={style.InterviewVoiceTextContainer}>
                  <div className={style.InterviewVoiceTextGroup}>
                    <p className={style.InterviewVoiceTextPosition}>
                      ラビエスト株式会社様
                    </p>
                    <p className={style.InterviewVoiceText}>
                      <span className={style.InterviewVoiceStrong}>
                        画面がシンプルで直感的に使うことができ
                      </span>
                      、見積や報告画像、入居者情報の確認も
                      <span className={style.InterviewVoiceStrong}>
                        ひとつの画面で完結
                      </span>
                      するので
                      <span className={style.InterviewVoiceStrong}>
                        とてもスムーズ
                      </span>
                      です。
                    </p>
                  </div>

                  <div className={style.InterviewLinkContainer}>
                    <a
                      native
                      href="https://magazine.itandi.co.jp/casestudy/2523/"
                      className={style.InterviewLink}
                    >
                      インタビュー記事へ
                      <img
                        className={style.InterviewArrowIcon}
                        src="/assets/naiso_kouji_omakase/chevron-right.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.InterviewVoiceCardWrapper}>
              <div className={style.InterviewVoiceCard}>
                <div className={style.InterviewVoiceImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/interview_voice_03.png"
                    alt=""
                    className={style.InterviewVoiceImage}
                  />
                </div>
                <div className={style.InterviewVoiceTextContainer}>
                  <div className={style.InterviewVoiceTextGroup}>
                    <p className={style.InterviewVoiceTextPosition}>
                      株式会社diff様
                    </p>
                    <p className={style.InterviewVoiceText}>
                      <span className={style.InterviewVoiceStrong}>
                        工期が短く、品質も満足
                      </span>
                      です。また撮影する写真枚数も多く、現地に行かなくても
                      <span className={style.InterviewVoiceStrong}>
                        細かい部分の状況まで把握
                      </span>
                      できています。
                    </p>
                  </div>
                  <div className={style.InterviewLinkContainer}>
                    <a
                      native
                      href="https://magazine.itandi.co.jp/casestudy/2501/"
                      className={style.InterviewLink}
                    >
                      インタビュー記事へ
                      <img
                        className={style.InterviewArrowIcon}
                        src="/assets/naiso_kouji_omakase/chevron-right.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className={style.InterviewVoiceCard}>
                <div className={style.InterviewVoiceImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/interview_voice_04.png"
                    alt=""
                    className={style.InterviewVoiceImage}
                  />
                </div>
                <div className={style.InterviewVoiceTextContainer}>
                  <div className={style.InterviewVoiceTextGroup}>
                    <p className={style.InterviewVoiceTextPosition}>
                      株式会社明日ホーム様
                    </p>
                    <p className={style.InterviewVoiceText}>
                      <span className={style.InterviewVoiceStrong}>
                        煩雑だったオペレーションを組み直すことができ
                      </span>
                      、そのおかげで契約や募集担当業務などの
                      <span className={style.InterviewVoiceStrong}>効率化</span>
                      にも繋がっています。
                    </p>
                  </div>
                  <div className={style.InterviewLinkContainer}>
                    <a
                      native
                      href="https://magazine.itandi.co.jp/casestudy/2117/"
                      className={style.InterviewLink}
                    >
                      インタビュー記事へ
                      <img
                        className={style.InterviewArrowIcon}
                        src="/assets/naiso_kouji_omakase/chevron-right.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={style.StepSection}>
        <div className={style.StepWrapper}>
          <SectionHeading title={`発注のステップ`} subTitle="STEP" />

          <div className={style.StepCardWrapper}>
            <div className={style.StepCard}>
              <div className={style.StepCardContainer}>
                <div className={style.StepCardImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/naiso_kouji_omakase_step01.png"
                    alt="利用登録画面"
                    className={style.StepCardImage}
                  />
                </div>
                <div className={style.StepCardTextContainer}>
                  <p className={style.StepNumber}>STEP1</p>
                  <p className={style.StepTitle}>内装工事くん利用登録</p>
                  <PrimaryButton
                    iconSrc="/assets/crm/icon/mail_white.svg"
                    text="管理会社様ご利用登録（無料）"
                    href="/zennichi/naiso-kouji-omakase/flow"
                  />
                </div>
              </div>
            </div>

            <div className={style.StepCard}>
              <div className={style.StepCardContainer}>
                <div className={style.StepCardImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/naiso_kouji_omakase_step02.png"
                    alt="案件登録する管理会社様"
                    className={style.StepCardImage}
                  />
                </div>
                <div className={style.StepCardTextContainer}>
                  <p className={style.StepNumber}>STEP2</p>
                  <p className={style.StepTitle}>案件を登録する</p>
                  <p className={style.StepText}>
                    内装工事くんの画面から案件の詳細を入力して退去立会や現地調査を依頼します。
                  </p>
                </div>
              </div>
            </div>

            <div className={style.StepCard}>
              <div className={style.StepCardContainer}>
                <div className={style.StepCardImageContainer}>
                  <img
                    src="/assets/naiso_kouji_omakase/naiso_kouji_omakase_step03.png"
                    alt="見積書や報告を待つ工事会社様"
                    className={style.StepCardImage}
                  />
                </div>
                <div className={style.StepCardTextContainer}>
                  <p className={style.StepNumber}>STEP3</p>
                  <p className={style.StepTitle}>見積書や報告を待つ</p>
                  <p className={style.StepText}>
                    案件登録したあとはパートナー施工会社様からの見積書や報告を待ちます。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={style.AreaSection}>
        <div className={style.AreaWrapper}>
          <SectionHeading title={`対応エリア`} subTitle="AREA" />
          <p className={style.AreaText}>
            埼玉県・千葉県・
            <br className={style.AreaTextBr} />
            東京都・神奈川県
            <span className={style.AreaAnnotation}>（順次拡大予定）</span>
          </p>
        </div>
      </section>

      <section className={style.PriceSection} id="price">
        <div className={style.PriceWrapper}>
          <SectionHeading title={`料金表`} subTitle="PRICE" />
          <div className={style.PriceImageWrapper}>
            <div className={style.PriceImageMonthlyWrapper}>
              <picture className={style.AboutImageWrapper}>
                <source
                  srcset="/assets/naiso_kouji_omakase/monthly_price_sp.svg"
                  media="(max-width: 500px)"
                />
                <source
                  srcset="/assets/naiso_kouji_omakase/monthly_price_tablet.svg"
                  media="(max-width: 1000px)"
                />
                <img
                  src="/assets/naiso_kouji_omakase/monthly_price_pc.svg"
                  alt="内装工事くん利用料=登録・利用料無料"
                  className={style.PriceImageMonthly}
                />
              </picture>
            </div>
            <div className={style.PriceImageConstructionWrapper}>
              <picture className={style.AboutImageWrapper}>
                <source
                  srcset="/assets/naiso_kouji_omakase/construction_price_sp.svg"
                  media="(max-width: 500px)"
                />
                <source
                  srcset="/assets/naiso_kouji_omakase/construction_price_tablet.svg"
                  media="(max-width: 1000px)"
                />
                <img
                  src="/assets/naiso_kouji_omakase/construction_price_pc.svg"
                  alt="クロス張替 単価=¥1,000 単位=㎡
                リペア補修(半人工) 単価=¥19,800 単位=半人工
                ハウスクリーニング(~25㎡) 単価=¥24,000 単位＝式
                ハウスクリーニング(25㎡~) 単価=¥950 単位＝㎡
                エアコン内部洗浄 単価=¥10,000 単位=式"
                  className={style.PriceImageConstruction}
                />
              </picture>
            </div>
            <div className={style.PriceImageExchangeWrapper}>
              <picture className={style.AboutImageWrapper}>
                <source
                  srcset="/assets/naiso_kouji_omakase/exchange_price_sp_202404.svg"
                  media="(max-width: 500px)"
                />
                <source
                  srcset="/assets/naiso_kouji_omakase/exchange_price_tablet_202404.svg"
                  media="(max-width: 1000px)"
                />
                <img
                  src="/assets/naiso_kouji_omakase/exchange_price_pc_202404.svg"
                  alt="エアコン交換 メーカー=三菱電機 種類=6畳 既設取替 材工価格=¥68,530~
                  エアコン交換 メーカー=三菱電機 種類=8畳 既設取替 材工価格=¥77,880~
                  エアコン取付 メーカー=三菱電機 種類=6畳 新設取付 材工価格=¥62,480~
                  エアコン取付 メーカー=三菱電機 種類=8畳 新設取付 材工価格=¥71,280~
                  給湯器交換 メーカー=リンナイ 種類=16号 既設取替 材工価格=¥74,800~
                  給湯器交換 メーカー=リンナイ 種類=20号 既設取替 材工価格=¥77,000~
                  浴室乾燥機交換(2室) メーカー=MAX 既設取替 材工価格=¥69,520~
                  3口ガスコンロ交換(グリルあり/都市ガス用) メーカー=リンナイ 種類=横幅60㎝ 既設取替 材工価格=¥59,400~"
                  className={style.PriceImageConstruction}
                />
              </picture>
            </div>
          </div>

          <div className={style.PriceButtonWrapper}>
            <SecondaryButton
              iconSrc="/assets/crm/icon/mail_white.svg"
              text="その他の料金について問い合わせる"
              href="#contact"
            />
          </div>
        </div>
      </section>

      <section className={style.ContractorSection}>
        <div className={style.ContractorWrapper}>
          <SectionHeading
            title={`パートナー施工会社様になりませんか？`}
            subTitle="CONTRACTOR"
            color="light"
          />
          <div className={style.ContractorTextWrapper}>
            <p className={style.ContractorText}>
              イタンジを利用している1000社以上の管理会社様からの案件を受注できる体制を整えております。繁忙期はもちろんのこと案件の少ない閑散期でも工事の依頼をさせていただきます。パートナー施工会社様になるために、まずはお問い合わせください。当社との面談を実施したあと工事の品質を確認した上で案件を請け負っていただきます。
            </p>
            <p className={style.ContractorAnnotation}>
              *施工エリアは一都三県になります。
            </p>
          </div>
          <div className={style.ContractorImageWrapper}>
            <div className={style.ContractorTitleWrapper}>
              <p className={style.ContractorTitle}>
                パートナー施工会社様のメリット
              </p>
            </div>
            <div className={style.ContractorItemWrapper}>
              <div className={style.ContractorItemContainer}>
                <div className={style.ContractorItemImageWrapper}>
                  <img
                    src="/assets/naiso_kouji_omakase/contractor_merit_estimate.png"
                    alt="見積書"
                    className={style.ContractorItemImage}
                  />
                </div>
                <p className={style.ContractorItemText}>簡単に見積作成</p>
              </div>
              <div className={style.ContractorItemContainer}>
                <div className={style.ContractorItemImageWrapper}>
                  <img
                    src="/assets/naiso_kouji_omakase/contractor_merit_web.png"
                    alt="PC"
                    className={style.ContractorItemImage}
                  />
                </div>
                <p className={style.ContractorItemText}>やりとりはWebで完結</p>
              </div>
              <div className={style.ContractorItemContainer}>
                <div className={style.ContractorItemImageWrapper}>
                  <img
                    src="/assets/naiso_kouji_omakase/contractor_merit_partner.png"
                    alt="握手"
                    className={style.ContractorItemImage}
                  />
                </div>
                <p className={style.ContractorItemText}>
                  パートナー施工会社様限定案件
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={style.FAQSection}>
        <div className={style.FAQWrapper}>
          <SectionHeading title={`よくある質問`} subTitle="FAQ" />
          <div className={style.FAQList}>
            {faqList.map((faq) => (
              <FAQCard
                key={faq.questionText}
                questionText={faq.questionText}
                answerText={faq.answerTex}
              />
            ))}
          </div>
        </div>
      </section>

      <section className={style.ContactSection} id="contact">
        <div className={style.ContactWrapper} id="contact">
          <SectionHeading title={`資料請求\nお問い合わせ`} subTitle="CONTACT" />
          <ZennichiContactForm />
        </div>
      </section>

      <section className={style.CTASection}>
        <div className={style.CTAAreaFollow}>
          <div className={style.CTAAreaButtonWrapper}>
            <PrimaryButton
              iconSrc="/assets/crm/icon/mail_white.svg"
              text="工事見積を発注（無料）"
              href="/zennichi/naiso-kouji-omakase/flow"
            />
          </div>
        </div>
      </section>
    </main>
  </>
);

export default ZennichiNaisoKoujiOmakase;
